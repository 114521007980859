import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import {SectionWrapper} from "../../components/section-wrapper/sectionWrapper.styles";

import React, {useEffect, useState} from "react";
import toastr from "toastr";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selectors";
import {FaPlus, FaSortDown, FaSortUp} from "react-icons/fa";
import QuoteTool from "../../components/quotes/quote-tool.component";
import PDFGenerator from "../../components/quotes/quote-pdf.component";
import {usePagination, useSortBy, useTable} from "react-table";
import QuoteEditTool from "../../components/quotes/quote-edit-tool.component";
import {Button, Modal} from "react-bootstrap";

const groupInsuranceCoverageData = [
    {
        coverage: "Life Insurance",
        single: "$15,000",
        family: `$15,000 [Employee]\n$10,000 [Spouse]\n$5,000 [per Child]`
    },
    {
        coverage: "Accidental Death and Dismemberment",
        single: "$60,000",
        family: "$60,000 [Employee]"
    },
    {
        coverage: "Critical Illness",
        single: "$10,000",
        family: "$10,000 [Employee]"
    },
    {
        coverage: "Travel Emergency Medical (up to 60 days of travel)",
        single: "$2,000,000",
        family: "$2,000,000 [Each Family Member]"
    },
    {
        coverage: "Excess Medical Costs (over $2,500) (Stop Loss Coverage)",
        single: "$250,000",
        family: "$250,000"
    },
    {
        coverage: "Monthly Premium",
        single: "$29.99/month\n+ any HSA usage",
        family: "$39.99/month\n+ any HSA usage"
    }
];


const QuotesDashboard = ({ currentUser }) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LOCAL;
    const [proposalsList, setProposalsList] = useState([]);
    const [showQuoteTool, setShowQuoteTool] = useState(false);
    const [selectedProposal, setSelectedProposal] = useState(null);
    const [showQuoteEditTool, setShowQuoteEditTool] = useState(false);
    const [userLogo, setUserLogo] = useState("/logo.png");
    const [userColor, setUserColor] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [proposalToDelete, setProposalToDelete] = useState(null);
    
    useEffect(async () => {
        await getUserLogo();
        await getAllProposals();
    }, []);

    const getUserLogo = async () => {
        try {
            const accessToken = currentUser.token;
            const response = await fetch(`${apiUrl}/proposals/getuserdetails/${currentUser.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (data.advisorDetails) {
                    setUserLogo(data.advisorDetails.advisorLogo);
                    setUserColor(data.advisorDetails.advisorColor);
                }
            }
            else {
                console.error("HTTP Error:", response.status, response.statusText);
                return;
            }
        } catch (err) {
            toastr.error("Failed to retrieve advisor logo");
        }
    };

    
    const handleDeleteClick = (id) => {
        setProposalToDelete(id);
        setShowDeleteModal(true);
    }

    const handleConfirmDelete = async () => {
        try {
            const response = await fetch(`${apiUrl}/proposals/${proposalToDelete.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${currentUser.token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await getAllProposals();
        } catch (error) {
            console.error('Error:', error);
        }
        
        setShowDeleteModal(false);
    }
    
    const getAllProposals = async () => {
        try {
            const accessToken = currentUser.token;
            const response = await fetch(`${apiUrl}/proposals/${currentUser.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            
            if (response.ok) {
                const data = await response.json();
                setProposalsList(data.proposalsList);
            }
            else {
                console.error("HTTP Error:", response.status, response.statusText);
                return;
            }
        } catch (err) {
            toastr.error("Failed to retrieve proposals");
        }
    };

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'ID',
                accessor: (row, index) => index + 1,
            },
            {
                Header: 'Client Company',
                accessor: 'clientCompany',
            },
            {
                Header: 'Client Name',
                accessor: 'clientName',
            },
            {
                Header: 'Client Email',
                accessor: 'clientEmail',
            },
            {
                Header: 'Created At',
                accessor: (row) => row.createdAt ? new Date(row.createdAt).toISOString().split('T')[0] : '',
            },
            {
                Header: 'View',
                Cell: ({ row }) => (
                    <button
                        className="px-4 btn btn-outline-success btn-sm"
                        onClick={() => handleViewButtonClick(row.original)}
                    >
                        View
                    </button>
                ),
            },
            {
                Header: 'Download PDF',
                Cell: ({ row }) => (
                    <PDFGenerator proposal={row.original} groupInsuranceCoverageData={groupInsuranceCoverageData}
                                  userLogo={userLogo} userColor={userColor}/>
                ),
            },
            {
                Header: 'Delete',
                Cell: ({ row }) => (
                    <button
                        className="px-4 btn btn-outline-danger btn-sm"
                        onClick={() => handleDeleteClick(row.original)}
                    >
                        Delete
                    </button>
                ),
            },
        ];
    }, [userLogo, userColor]);

    const data = React.useMemo(() => proposalsList, [proposalsList]);

    const handleViewButtonClick = (proposal) => {
        setShowQuoteEditTool(true);
        setSelectedProposal(proposal);
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
            },
        },
        useSortBy,
        usePagination
    );
    
    return (
        <PageWrapper>
            <SectionWrapper title="Quotes">
                <div>
                    <div className="create-enrollment-button">
                        <button className="px-4 btn btn-primary"
                                onClick={() => setShowQuoteTool(true)}
                                style={{minWidth: 50}}>
                            <span className="d-none d-sm-inline me-2">Create</span>
                            <FaPlus className="d-inline-block"/>
                        </button>
                    </div>
                    <div className="table-scroll-container">
                        {data && data.length > 0 ? (
                            <>
                                <table {...getTableProps()} className="table table-hover pending-enrollment-table">
                                    <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render('Header')}
                                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                                <div className="pagination">
                                    <button className="pagination-button" onClick={() => gotoPage(0)}
                                            disabled={!canPreviousPage}>
                                        {'<<'}
                                    </button>
                                    <button className="pagination-button" onClick={() => previousPage()}
                                            disabled={!canPreviousPage}>
                                        {'<'}
                                    </button>
                                    <span>
                                        Page{' '}
                                                            <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{' '}
                                    </span>
                                    <button className="pagination-button" onClick={() => nextPage()}
                                            disabled={!canNextPage}>
                                        {'>'}
                                    </button>
                                    <button className="pagination-button"
                                            onClick={() => gotoPage(pageOptions.length - 1)}
                                            disabled={!canNextPage}>
                                        {'>>'}
                                    </button>
                                    <select
                                        className="pagination-select"
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map(size => (
                                            <option key={size} value={size}>
                                                Show {size}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        ) : (
                            <p>No proposals available. Click "Create" to generate a new one.</p>
                        )}
                    </div>

                </div>
            </SectionWrapper>
            <QuoteTool currentUser={currentUser} showQuoteTool={showQuoteTool} setShowQuoteTool={setShowQuoteTool}/>
            <QuoteEditTool currentUser={currentUser} proposal={selectedProposal}
                           groupInsuranceCoverageData={groupInsuranceCoverageData}
                           showQuoteEditTool={showQuoteEditTool}
                           setShowQuoteEditTool={setShowQuoteEditTool}/>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this enrollment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageWrapper>
    )
}


const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(QuotesDashboard);