import {useEffect, useState} from "react";

const hsaLsaFlexPercentages = [0.4, 0.65, 0.8, 1.0];
const chFlexPercentages = [0.5, 0.75, 1.0];
const solePropPercentages = [0.7, 0.85, 1.0];

// 5% = AB 1, BC 2, MB 3, NT 11, NU 12, QC 10, SK 9, YT 13
// 13% = ON 7
// 15% = NB 4, NL 5, NS 6, PE 8
const provincesTaxRates = {
    AB: 0.05,
    BC: 0.05,
    MB: 0.05,
    NB: 0.15,
    NL: 0.15,
    NT: 0.05,
    NS: 0.15,
    NU: 0.05,
    ON: 0.13,
    PE: 0.15,
    QC: 0.05,
    SK: 0.05,
    YT: 0.05
};

const accountTypeHeaders = {
    HSA: "Health Spending Account",
    LSA: "Lifestyle Spending Account",
    CoreHealth: "CoreHealth Account",
    Flex: "Flexible Spending Account"
}

export const QuoteEstimates = ({ proposal, groupInsuranceCoverageData }) => {
    const [totalClaimSoleProp, setTotalClaimSoleProp] = useState(0);
    const [totals, setTotals] = useState({});
    const [customPercentage, setCustomPercentage] = useState({});
    const [customCHPercentage, setCustomCHPercentage] = useState(0);
    const [hasCHAccount, setHasCHAccount] = useState(false);
    const [companyType, setCompanyType] = useState("");
    const [planAccounts, setPlanAccounts] = useState([]);
    const [totalInsurancePremium, setTotalInsurancePremium] = useState(0);

    useEffect(() => {
        if (proposal && proposal.planAccount) {
            setPlanAccounts(JSON.parse(proposal.planAccount));
            setCompanyType(proposal.companyType);
        }
    }, []);
    
    useEffect(() => {
        if (planAccounts.length !== 0) {
            try {
                // if (proposal.companyType?.trim() === "Incorporated") {
                    const calculatedTotals = planAccounts.reduce((acc, account) => {
                        const {accountType, benefitCategories} = account;
                        const chNoOfEmployees = { single: 0, couple: 0, family: 0 };

                        if (!acc[accountType]) {
                            acc[accountType] = { single: 0, couple: 0, family: 0 };
                        }

                        benefitCategories.forEach((category) => {
                            const {single, couple, family} = category.limits;

                            acc[accountType].single +=
                                parseFloat(single.limitPerEmployee) *
                                parseInt(single.noOfEmployees, 10);

                            acc[accountType].couple +=
                                parseFloat(couple.limitPerEmployee) *
                                parseInt(couple.noOfEmployees, 10);

                            acc[accountType].family +=
                                parseFloat(family.limitPerEmployee) *
                                parseInt(family.noOfEmployees, 10);

                            if (accountType === "CoreHealth") {
                                chNoOfEmployees["single"] += parseInt(single.noOfEmployees, 10);
                                chNoOfEmployees["couple"] += parseInt(couple.noOfEmployees, 10);
                                chNoOfEmployees["family"] += parseInt(family.noOfEmployees, 10);
                            }
                        });

                        // Total Insurance Premium Calculation
                        calculateInsurancePremiums(chNoOfEmployees);
                        
                        return acc;
                    }, {});

                    setTotals(calculatedTotals);

                    // Display Group Insurance Coverage Details
                    setHasCHAccount(planAccounts.some(
                        (account) => account.accountType === "CoreHealth"
                    ));
                // }
                // else {
                //     setTotalClaimSoleProp(planAccounts.totalClaimSoleProp);
                // }
            } catch (error) {
                console.error("Error parsing or calculating totals:", error);
            }
        }
    }, [planAccounts]);

    const handleCustomPercentageChange = (accountType, value) => {
        setCustomPercentage((prev) => ({
            ...prev,
            [accountType]: parseFloat(value) || 0,
        }));
    };

    const calculateCost = (limits, percent) => ({
        single: limits.single * percent,
        couple: limits.couple * percent,
        family: limits.family * percent,
    });

    const calculateAdminFee = (limits, percent) => {
        return (
            (limits.single * percent +
                limits.couple * percent +
                limits.family * percent) *
            proposal.adminFee /
            100
        );
    };

    const calculateTax = (adminFeeTotal) => {
        return adminFeeTotal * provincesTaxRates[proposal.province];
    };

    const calculateTotalCost = (limits, percent, accountType) => {
        const limitsTotal =
            limits.single * percent +
            limits.couple * percent +
            limits.family * percent;
        const adminFee = calculateAdminFee(limits, percent);
        const tax = calculateTax(adminFee);
        
        const total = limitsTotal + adminFee + tax;
        return accountType !== "CoreHealth" ? total : total + parseFloat(totalInsurancePremium);
    };

    const calculateTotalCostWithAdditionalTax = (limits, percent, accountType) => {
        const limitsTotal =
            limits.single * percent +
            limits.couple * percent +
            limits.family * percent;
        const adminFee = calculateAdminFee(limits, percent);
        const tax = calculateTax(adminFee);
        const addtlTax = calculateAdditionalONTax(limits, percent);
        
        if (accountType === "CoreHealth") {
            return limitsTotal + adminFee + tax + addtlTax + parseFloat(totalInsurancePremium);
        }
        return limitsTotal + adminFee + tax + addtlTax;
    };

    const calculateCustomCost = (limits, customPercent) => {
        const percent = customPercent / 100;
        return {
            limitsTotal:
                limits.single * percent +
                limits.couple * percent +
                limits.family * percent,
            adminFee: calculateAdminFee(limits, percent),
            tax: calculateTax(calculateAdminFee(limits, percent)),
        };
    };
    
    const calculateTotalCustomCost = (customCosts, accountType, province) => {
        let total = customCosts.limitsTotal + customCosts.adminFee + customCosts.tax;
        
        if (province === "ON") {
            total += calculateAdditionalONTaxCustomCost(customCosts)
        }
        if (accountType === "CoreHealth") {
            total += parseFloat(totalInsurancePremium)
        }
        return total;
    }
    
    const calculateAdditionalONTaxCustomCost = (customLimits) => {
        return customLimits.limitsTotal * 0.08 + (customLimits.limitsTotal + customLimits.adminFee) * 0.02;
    }
    
    const calculateAdditionalONTax = (limits, percent) => {
        const limitsTotal =
            limits.single * percent +
            limits.couple * percent +
            limits.family * percent;
        const adminFee = calculateAdminFee(limits, percent);
        return limitsTotal * 0.08 + (limitsTotal + adminFee) * 0.02;
    }
    
    const calculateInsurancePremiums = (chNoOfEmployees) => {
        var singleRate = 29.99; // the monthly insurance premium for single
        var coupleRate = 39.99; // the monthly insurance premium for couples
        var familyRate = 39.99; // the monthly insurance premium for family
        var hsaCost = 0.1; // the percentage of HSA spend that it costs the client
        
        setTotalInsurancePremium((chNoOfEmployees.single * singleRate * 12 + 
            chNoOfEmployees.couple * coupleRate * 12 + 
            chNoOfEmployees.family * familyRate * 12).toFixed(2));
    }
    
    return (
        <div>
            <div className="shadow-lg p-3 bg-white" hidden={companyType.trim() !== "Incorporated"}>
                <h3>Estimates</h3>
                {Object.entries(totals).map(([accountType, limits]) => {    
                    const customPercent = customPercentage[accountType] || 0;
                    const customCosts = calculateCustomCost(limits, customPercent);
                    
                    // const percentages = accountType === "HSA" || accountType === "LSA" || accountType === "Flex" ? hsaLsaFlexPercentages : chFlexPercentages;
                    const percentages = hsaLsaFlexPercentages;
                    
                    return (
                        <div key={accountType}>
                            <h3>{accountTypeHeaders[accountType]}</h3>
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <td></td>
                                    <th>Typical Usage</th>
                                    {/*{(accountType === "HSA" || accountType === "LSA" || accountType === "Flex") && <th>Typical Usage</th>}*/}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>Custom % Usage</th>
                                </tr>
                                </thead>
                                <tbody style={{textAlign: "center"}}>
                                <tr>
                                    <th>% {accountType} Used</th>
                                    {percentages.map((percent, idx) => (
                                        <th key={idx}>{(percent * 100)}%</th>
                                    ))}
                                    <td>
                                        <input
                                            type="number"
                                            style={{width: "50%"}}
                                            className="form-control"
                                            value={customPercent}
                                            onChange={(e) =>
                                                handleCustomPercentageChange(accountType, e.target.value)
                                            }
                                        />
                                    </td>
                                </tr>
                                {["Single", "Couple", "Family"].map((category) => (
                                    <tr key={category}>
                                        <th>{`${accountType} $ Spend (${category})`}</th>
                                        {percentages.map((percent, idx) => (
                                            <td key={idx} style={{textAlign: "right"}}>
                                                ${calculateCost(limits, percent)[
                                                category.toLowerCase()
                                                ]}
                                            </td>
                                        ))}
                                        <td style={{textAlign: "right"}}>
                                            ${(limits[category.toLowerCase()] * customPercent / 100).toFixed(2)}
                                        </td>
                                    </tr>
                                ))}
                                {proposal && accountType === "CoreHealth" && (
                                    <tr>
                                        <th>Total Insurance Premium</th>
                                        <td style={{textAlign: "right"}}>${totalInsurancePremium}</td>
                                        <td style={{textAlign: "right"}}>${totalInsurancePremium}</td>
                                        <td style={{textAlign: "right"}}>${totalInsurancePremium}</td>
                                        <td style={{textAlign: "right"}}>${totalInsurancePremium}</td>
                                    </tr>
                                )}
                                <tr>
                                <th>{accountType} Admin Fee</th>
                                    {percentages.map((percent, idx) => (
                                        <td key={idx} style={{textAlign: "right"}}>
                                            ${calculateAdminFee(limits, percent).toFixed(2)}
                                        </td>
                                    ))}
                                    <td style={{textAlign: "right"}}>${customCosts.adminFee.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <th>GST/HST*</th>
                                    {percentages.map((percent, idx) => (
                                        <td key={idx} style={{textAlign: "right"}}>
                                            ${calculateTax(calculateAdminFee(limits, percent)).toFixed(
                                            2
                                        )}
                                        </td>
                                    ))}
                                    <td style={{textAlign: "right"}}>${customCosts.tax.toFixed(2)}</td>
                                </tr>
                                {proposal && proposal.province === "ON" && accountType !== "LSA" ?
                                    <>
                                        <tr>
                                            <th>Additional ON Tax**</th>
                                            {percentages.map((percent, idx) => (
                                                <td key={idx} style={{textAlign: "right"}}>
                                                    ${calculateAdditionalONTax(limits, percent)
                                                    .toFixed(2)}
                                                </td>
                                            ))}
                                            <td style={{textAlign: "right"}}>${calculateAdditionalONTaxCustomCost(customCosts, customPercent).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Cost (Annual Limit)</th>
                                            {percentages.map((percent, idx) => (
                                                <td key={idx} style={{textAlign: "right"}}>
                                                    ${calculateTotalCostWithAdditionalTax(limits, percent, accountType).toFixed(2)}
                                                </td>
                                            ))}
                                            <td style={{textAlign: "right"}}>
                                                ${calculateTotalCustomCost(customCosts, accountType).toFixed(2)}
                                            </td>
                                        </tr>
                                    </>
                                    :
                                    <tr>
                                        <th>Total Cost (Annual Limit)</th>
                                        {percentages.map((percent, idx) => (
                                            <td key={idx} style={{textAlign: "right"}}>
                                                ${(calculateTotalCost(limits, percent, accountType)).toFixed(2)}
                                            </td>
                                        ))}
                                        <td style={{textAlign: "right"}}>
                                            ${(calculateTotalCustomCost(customCosts, accountType)).toFixed(2)}
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    );
                })}
                <p style={{fontSize: "12px"}}>* {proposal && provincesTaxRates[proposal.province] * 100}% provincial
                    GST/HST rate.</p>
                {proposal && proposal.province === "ON" &&
                    <p style={{fontSize: "12px"}}>
                        ** Additional ON tax includes 8% RST & 2% premium tax on the claim amount, 13% HST & 2% premium
                        Tax on the admin fee.<br/><br/>
                        Note: Calculation includes GST/HST for total dollars spent
                        regardless of HSA or LSA allocation.
                        Provincial taxes not applicable on any LSA reimbursements when the plan goes live. LSA claim
                        amount is exempt from RST and will be reimbursed.</p>}
                {hasCHAccount && 
                    <GroupInsuranceCoverage groupInsuranceCoverageData={groupInsuranceCoverageData}></GroupInsuranceCoverage>}
        </div>
        {/*<div className="shadow-lg p-3 bg-white" hidden={planAccounts.length > 0 && !planAccounts.some(acct => acct.accountType === "CoreHealth")}>*/}
        {/*    <h2>CoreHealth+ Estimates</h2>*/}
        {/*        {proposal !== null &&*/}
        {/*        <table className="table table-hover">*/}
        {/*            <thead>*/}
        {/*            <tr>*/}
        {/*                <th colSpan="4"></th>*/}
        {/*                <th>Custom % Usage</th>*/}
        {/*            </tr>*/}
        {/*            </thead>*/}
        {/*            <tbody>*/}
        {/*            <tr>*/}
        {/*                <th>% Core Used</th>*/}
        {/*                {solePropPercentages.map((percent, idx) => (*/}
        {/*                    <th key={idx}>{percent * 100}%</th>*/}
        {/*                ))}*/}
        {/*                <td style={{textAlign: "right"}}>*/}
        {/*                    <input*/}
        {/*                        type="number"*/}
        {/*                        style={{width: "50%"}}*/}
        {/*                        className="form-control"*/}
        {/*                        value={customCHPercentage}*/}
        {/*                        onChange={(e) => setCustomCHPercentage(e.target.value)}*/}
        {/*                    />*/}
        {/*                </td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*                <th>Core $ Spend</th>*/}
        {/*                {solePropPercentages.map((percent, idx) => (*/}
        {/*                    <td key={idx} style={{textAlign: "right"}}>${(totalClaimSoleProp * percent).toFixed(2)}</td>*/}
        {/*                ))}*/}
        {/*                <td style={{textAlign: "right"}}>${(totalClaimSoleProp * customCHPercentage / 100).toFixed(2)}</td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*                <th>Total Insurance Premium</th>*/}
        {/*                <td style={{textAlign: "right"}}>$479</td>*/}
        {/*                <td style={{textAlign: "right"}}>$479</td>*/}
        {/*                <td style={{textAlign: "right"}}>$479</td>*/}
        {/*                <td style={{textAlign: "right"}}>$479</td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*                <th>Core Admin Fee</th>*/}
        {/*                {solePropPercentages.map((percent, idx) => (*/}
        {/*                    <td key={idx} style={{textAlign: "right"}}>${(totalClaimSoleProp * percent * 0.10).toFixed(2)}</td>*/}
        {/*                ))}*/}
        {/*                <td style={{textAlign: "right"}}>${(totalClaimSoleProp * customCHPercentage / 100 * 0.10).toFixed(2)}</td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*                <th>GST/HST*</th>*/}
        {/*                {solePropPercentages.map((percent, idx) => (*/}
        {/*                    <td key={idx} style={{textAlign: "right"}}>*/}
        {/*                        ${((totalClaimSoleProp * percent * 0.10) * provincesTaxRates[proposal.province]).toFixed(2)}*/}
        {/*                    </td>*/}
        {/*                ))}*/}
        {/*                <td style={{textAlign: "right"}}>${((totalClaimSoleProp * customCHPercentage / 100 * 0.10) * provincesTaxRates[proposal.province]).toFixed(2)}</td>*/}
        {/*            </tr>*/}
        {/*            {proposal.province === "ON" ? <>*/}
        {/*                <tr>*/}
        {/*                    <th>Additional ON Tax**</th>*/}
        {/*                    {solePropPercentages.map((percent, idx) => (*/}
        {/*                        <td key={idx} style={{textAlign: "right"}}>*/}
        {/*                            ${((totalClaimSoleProp * percent * 0.08) +*/}
        {/*                            (totalClaimSoleProp * percent + totalClaimSoleProp * percent * 0.10) * 0.02).toFixed(2)}*/}
        {/*                        </td>*/}
        {/*                    ))}*/}
        {/*                    <td style={{textAlign: "right"}}>${((totalClaimSoleProp * customCHPercentage / 100 * 0.08) +*/}
        {/*                        (totalClaimSoleProp * customCHPercentage / 100 + totalClaimSoleProp * customCHPercentage / 100 * 0.10) * 0.02).toFixed(2)}</td>*/}
        {/*                </tr>*/}
        {/*                <tr>*/}
        {/*                    <th>Total Cost (plus applicable taxes)</th>*/}
        {/*                    {solePropPercentages.map((percent, idx) => (*/}
        {/*                        <td key={idx} style={{textAlign: "right"}}>*/}
        {/*                            ${((totalClaimSoleProp * percent) + 479 + (totalClaimSoleProp * percent * 0.10)*/}
        {/*                            + (totalClaimSoleProp * percent * 0.10) * provincesTaxRates[proposal.province]*/}
        {/*                            + (totalClaimSoleProp * percent * 0.08) +*/}
        {/*                            (totalClaimSoleProp * percent + totalClaimSoleProp * percent * 0.10) * 0.02).toFixed(2)}*/}
        {/*                        </td>*/}
        {/*                    ))}*/}
        {/*                    <td style={{textAlign: "right"}}>*/}
        {/*                        ${((totalClaimSoleProp * customCHPercentage / 100 + 479 +*/}
        {/*                        totalClaimSoleProp * customCHPercentage / 100 * 0.10 +*/}
        {/*                        totalClaimSoleProp * customCHPercentage / 100 * 0.10 * provincesTaxRates[proposal.province] +*/}
        {/*                        (totalClaimSoleProp * customCHPercentage / 100 * 0.08) +*/}
        {/*                        (totalClaimSoleProp * customCHPercentage/100 + totalClaimSoleProp * customCHPercentage/100 * 0.10) * 0.02)).toFixed(2)}*/}
        {/*                    </td>*/}
        {/*                </tr></>*/}
        {/*                : */}
        {/*                <tr>*/}
        {/*                    <th>Total Cost (plus applicable taxes)</th>*/}
        {/*                    {solePropPercentages.map((percent, idx) => (*/}
        {/*                        <td key={idx} style={{textAlign: "right", fontWeight: "bold"}}>*/}
        {/*                            ${((totalClaimSoleProp * percent) + 479 + (totalClaimSoleProp * percent * 0.10)*/}
        {/*                            + (totalClaimSoleProp * percent * 0.10) * provincesTaxRates[proposal.province]).toFixed(2)}*/}
        {/*                        </td>*/}
        {/*                    ))}*/}
        {/*                    <td style={{textAlign: "right"}}>*/}
        {/*                        ${(totalClaimSoleProp * customCHPercentage / 100 + 479 +*/}
        {/*                        totalClaimSoleProp * customCHPercentage / 100 * 0.10 +*/}
        {/*                        totalClaimSoleProp * customCHPercentage / 100 * 0.10 * provincesTaxRates[proposal.province]).toFixed(2)}*/}
        {/*                    </td>*/}
        {/*                </tr>*/}
        {/*            }*/}
        {/*            </tbody>*/}
        {/*        </table>}*/}
        {/*    </div>*/}
        {/*</div>*/}
        </div>
    )
}


const GroupInsuranceCoverage = ({groupInsuranceCoverageData}) => {
    return (
        <div>
            <h4>Group Insurance Coverage</h4>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th>Coverage</th>
                    <th>Single</th>
                    <th>Family</th>
                </tr>
                </thead>
                <tbody>
                {groupInsuranceCoverageData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.coverage}</td>
                        <td>{item.single}</td>
                        <td>
                            {item.family.split("\n").map((line, i) => (
                                <div key={i}>{line}</div>
                            ))}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}