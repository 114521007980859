import React, {useEffect, useState} from "react";
import axios from "axios";
import { FaTrashAlt } from "react-icons/fa"; // Icon for delete functionality

const FileUpload = ({ currentUser }) => {
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [color, setColor] = useState(currentUser.customColor);
    const [status, setStatus] = useState('');

    const apiUrl =
        process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_API_URL_PROD
            : process.env.NODE_ENV === "development"
                ? process.env.REACT_APP_API_URL_DEV
                : process.env.REACT_APP_API_URL_LOCAL;

    const saveColor = async (e) => {
        try {
            setColor(e.target.value);
            const accessToken = currentUser.token;
            const response = await fetch(`${apiUrl}/advisors/updatecolor/${currentUser.id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: `"${e.target.value}"`,
            });

            if (response.status === 200) {
                setStatus('Color saved successfully!');
            } else {
                setStatus('Failed to save color.');
            }
        } catch (error) {
            console.error('Error saving color:', error);
            setStatus('An error occurred while saving the color.');
        }
    };
    
    useEffect(() => {
        setUploadedImageUrl(currentUser.customLogo);
    }, [currentUser.customLogo]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        handleUpload(selectedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        handleUpload(droppedFile);
    };

    const handleUpload = async (file) => {
        if (!file) {
            alert("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            setUploadStatus("Uploading...");
            const response = await axios.post(
                `${apiUrl}/advisors/upload/${currentUser.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            setUploadStatus("Upload successful!");
            setUploadedImageUrl(response.data.fileUrl || null);
            setFile(null);
        } catch (error) {
            setUploadStatus("Upload failed.");
            console.error("Error uploading file:", error);
        }
    };

    const handleDelete = async () => {
        try {
            setUploadStatus("Deleting...");
            await axios.post(`${apiUrl}/advisors/delete/${currentUser.id}`);

            setUploadStatus("Deletion successful!");
            setUploadedImageUrl(null);
            setFile(null);
        } catch (error) {
            setUploadStatus("Delete failed.");
            console.error("Error deleting file:", error);
        }
    };

    return (
        <div style={{paddingTop: "20px", display: "flex", alignItems: "center", gap: "20px"}}>
            {!uploadedImageUrl ? (
                <div
                    style={{
                        border: isDragging ? "2px dashed blue" : "2px dashed gray",
                        padding: "50px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundColor: isDragging ? "#f0f8ff" : "#fafafa",
                        width: "70%",
                        height: "200px",
                        boxSizing: "border-box",
                    }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <p style={{margin: "0", fontSize: "16px"}}>
                        Drag and drop a file here, or click to select one.
                    </p>
                    <input
                        type="file"
                        style={{display: "none"}}
                        id="file-input"
                        onChange={handleFileChange}
                    />
                    <label
                        htmlFor="file-input"
                        style={{
                            display: "inline-block",
                            marginTop: "10px",
                            padding: "5px 10px",
                            background: "blue",
                            color: "white",
                            cursor: "pointer",
                        }}
                    >
                        Select File
                    </label>
                    {file && <p style={{marginTop: "10px"}}>{file.name}</p>}
                </div>
            ) : (
                <div
                    style={{
                        width: "70%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        border: "2px dashed gray",
                        borderRadius: "8px",
                        backgroundColor: "#fafafa",
                    }}
                >
                    <img
                        src={uploadedImageUrl}
                        alt="Uploaded Preview"
                        style={{
                            maxHeight: "150px",
                            maxWidth: "600px",
                            borderRadius: "8px",
                        }}
                    />
                    <FaTrashAlt
                        onClick={handleDelete}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer",
                            color: "red",
                        }}
                    />
                </div>
            )}
            <div style={{ border: "2px dashed gray",
                            padding: "50px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            backgroundColor: isDragging ? "#f0f8ff" : "#fafafa",
                            width: "30%",
                            height: "200px",
                            boxSizing: "border-box",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
            }}>
                <input
                    type="color"
                    value={color}
                    onChange={saveColor}
                    style={{
                        width: "150px",
                        height: "50px",
                        cursor: "pointer"
                    }}
                />
            </div>
        </div>
    );
};

export default FileUpload;
