import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {darceyOliverFont} from "../../fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["DarceyOliver.otf"] = darceyOliverFont;

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }, DarceyOliver: {
        normal: 'DarceyOliver.otf'
    }
}

const PDFGenerator = ({ proposal, groupInsuranceCoverageData, userLogo, userColor }) => {
    const planAccountBudget = {};
    let planAcct = {};
    let headerBody = [];

    // Date
    const today = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);

    // Declaration of variables
    let bencatTables = {};
    let accountTotals = {};
    let accountEstimates = {};
    let totals = {"single": 0, "couple": 0, "family": 0};
    let hasCoreHealth = false;
    let customCHPercent = 0;
    let totalPremiumInsurance = 0;
    
    // Cost Estimates
    const customPercent = 0;
    const percentages = [0.4, 0.65, 0.8, 1.0, customPercent / 100];
    const chPercentages = [0.5, 0.75, 1.0, customPercent / 100];
    const provincesTaxRates = {
        AB: 0.05,
        BC: 0.12,
        MB: 0.13,
        NB: 0.15,
        NL: 0.15,
        NT: 0.05,
        NS: 0.15,
        ON: 0.13,
        PE: 0.15,
        QC: 0.14975,
        SK: 0.06,
        YT: 0.05
    };
    
    if (proposal !== null) {
        let planAccount = JSON.parse(proposal.planAccount);
        
        hasCoreHealth = planAccount.some(account => account.accountType === "CoreHealth");
        
        planAccount.forEach((acct) => {
            bencatTables[acct.accountType] = [
                [{text: 'Job Description', bold: true}, {text: 'Single', bold: true}, 
                {text: 'Couple', bold: true}, {text: 'Family', bold: true}]
            ];

            let totalSingle = 0;
            let totalCouple = 0;
            let totalFamily = 0;

            planAccountBudget[acct.accountType] = 0;
            let accountLimitTotals = {"single": 0, "couple": 0, "family": 0};
            acct.benefitCategories.forEach((cat) => {
                accountLimitTotals["single"] += cat.limits.single.noOfEmployees * cat.limits.single.limitPerEmployee;
                accountLimitTotals["couple"] += cat.limits.couple.noOfEmployees * cat.limits.couple.limitPerEmployee;
                accountLimitTotals["family"] += cat.limits.family.noOfEmployees * cat.limits.family.limitPerEmployee;

                bencatTables[acct.accountType].push([
                    cat.description,
                    `$${cat.limits.single.limitPerEmployee}`,
                    `$${cat.limits.couple.limitPerEmployee}`,
                    `$${cat.limits.family.limitPerEmployee}`
                ]);

                // Accumulate employee counts
                totalSingle += parseInt(cat.limits.single.noOfEmployees, 10);
                totalCouple += parseInt(cat.limits.couple.noOfEmployees, 10);
                totalFamily += parseInt(cat.limits.family.noOfEmployees, 10);
            });

            accountEstimates[acct.accountType] = accountLimitTotals;
            accountTotals[acct.accountType] = {
                totalSingle,
                totalCouple,
                totalFamily
            };

            Object.values(accountTotals).forEach((account) => {
                totals.single += account.totalSingle;
                totals.couple += account.totalCouple;
                totals.family += account.totalFamily;
            });

            // Total Insurance Premium Calculation
            if (acct.accountType === "CoreHealth") {
                const singleRate = 29.99; // the monthly insurance premium for single
                const coupleRate = 39.99; // the monthly insurance premium for couples
                const familyRate = 39.99; // the monthly insurance premium for family

                totalPremiumInsurance = ((accountTotals["CoreHealth"].totalSingle * singleRate * 12 +
                    accountTotals["CoreHealth"].totalCouple * coupleRate * 12 +
                    accountTotals["CoreHealth"].totalFamily * familyRate * 12).toFixed(2));
            }
            
            headerBody = [
                [{ text: 'Company:', bold: true }, proposal.clientCompany],
                [{ text: 'Company Type:', bold: true }, proposal.companyType],
                [{ text: 'Single Employees:', bold: true }, totals.single],
                [{ text: 'Couples:', bold: true }, totals.couple],
                [{ text: 'Employees with Family:', bold: true }, totals.family]
            ];
            
            // Plan Account Budget
            planAccountBudget[acct.accountType] = accountEstimates[acct.accountType].single +
                accountEstimates[acct.accountType].couple + accountEstimates[acct.accountType].family;
        });
    }
    
    const accountTypeHeaders = {
        HSA: "Health Spending Account",
        LSA: "Lifestyle Spending Account",
        CoreHealth: "CoreHealth+ Account",
        Flex: "Flexible Spending Account"
    }
    
    const accountTypeCoverageSummaries = {
        HSA: [
            "Dental (Basic & Major)",
            "Orthodontics",
            "Prescription Drugs",
            "Paramedical Services",
            "Vision care",
            "Physiotherapy",
            "Chiropractor"
        ],
        LSA: ["LSA expenses are chosen on the master application at time of enrollment."],
        CoreHealth: [
            "Dental (Basic & Major)",
            "Orthodontics",
            "Prescription Drugs",
            "Paramedical Services",
            "Vision care",
            "Physiotherapy",
            "Chiropractor"
        ],
        Flex: [
            "Dental (Basic & Major)",
            "Orthodontics",
            "Prescription Drugs",
            "Paramedical Services",
            "Vision care",
            "Physiotherapy",
            "Chiropractor",
            "LSA expenses are chosen on the master application at time of enrollment."
        ]
    }
    
    // CoreHealth Group Insurance
    const groupInsuranceCoverageTable = [
        [
            { text: "Coverage", bold: true },
            { text: "Single", bold: true },
            { text: "Family", bold: true }
        ]
    ];

    groupInsuranceCoverageData && groupInsuranceCoverageData.forEach((item) => {
        groupInsuranceCoverageTable.push([item.coverage, item.single, item.family]);
    });

    const generateRow = (label, values) => {
        return [label, ...values.map(value => ({text:`$${value.toFixed(2)}`, alignment: 'right'}))];
    };

    const calculateValues = (baseValue) => {
        return percentages.map(percent => baseValue * percent);
    };
    
    const tableHeaders = (accountType, percentages) => {
        const pHeaders = percentages.map(percent => `${(percent * 100).toFixed(0)}%`);
        return [{text: `% ${accountType} Used`, bold: true}, ...pHeaders];
    }

    const calculateAdminFee = (estimates, adminFee) => {
        return percentages.map(percent =>
            (estimates["single"] * percent + estimates["couple"] * percent + estimates["family"] * percent) * adminFee/100
        );
    };

    const calculateTax = (adminFees) => {
        return adminFees.map(fee =>
            (fee * provincesTaxRates[proposal.province])
        );
    };
    const calculateAdditionalONTax = (estimates, adminFees) => {
        return percentages.map((percent, index) => {
            const total = estimates["single"] * percent + estimates["couple"] * percent + estimates["family"] * percent;
            return total * 0.08 + (total + adminFees[index]) * 0.02;
        });
    };

    const calculateTotalWithGSTAndONTax = (estimates, adminFees, taxValues, onTaxValues) => {
        return percentages.map((percent, idx) =>
            (estimates["single"] * percent + estimates["couple"] * percent + estimates["family"] * percent) +
            adminFees[idx] + taxValues[idx] + (onTaxValues ? onTaxValues[idx] : 0)
        );
    };
    
    const generateCostEstimatesTable = (accountType, accountEstimates, customPercent, proposal) => {
        const adminFees = calculateAdminFee(accountEstimates[accountType], proposal.adminFee);
        const taxValues = calculateTax(adminFees);
        const onTaxValues = proposal.province === "ON" && (accountType.trim() === "HSA" || accountType.trim() === "CoreHealth") 
            ? calculateAdditionalONTax(accountEstimates[accountType], adminFees)
            : null;
        let totalValues = calculateTotalWithGSTAndONTax(
            accountEstimates[accountType],
            adminFees,
            taxValues,
            onTaxValues
        );
        
        const rows = [
            tableHeaders(accountType, percentages),
            generateRow(
                {text: `${accountType} $ Spend (Single)`, bold: true},
                calculateValues(accountEstimates[accountType]["single"], percentages)
            ),
            generateRow(
                {text:`${accountType} $ Spend (Couple)`, bold: true},
                calculateValues(accountEstimates[accountType]["couple"], percentages)
            ),
            generateRow(
                {text:`${accountType} $ Spend (Family)`, bold: true},
                calculateValues(accountEstimates[accountType]["family"], percentages)
            ),
        ];

        if (accountType === "CoreHealth") {
            rows.push([
                {text: "Total Premium Insurance", bold: true},
                {text: `$${totalPremiumInsurance}`, alignment: "right"},
                {text: `$${totalPremiumInsurance}`, alignment: "right"},
                {text: `$${totalPremiumInsurance}`, alignment: "right"},
                {text: `$${totalPremiumInsurance}`, alignment: "right"},
                {text: `$${totalPremiumInsurance}`, alignment: "right"}])
            
            totalValues = totalValues.map(vals => parseFloat(vals) + parseFloat(totalPremiumInsurance));
        }
        
        rows.push(generateRow({text:`${accountType} Admin Fee`, bold: true}, adminFees));
        rows.push(generateRow({text: "GST/HST", bold: true}, taxValues));

        if (onTaxValues) {
            rows.push(generateRow({text: "Additional ON Tax**", bold: true}, onTaxValues));
        }

        rows.push(generateRow({text: "Total", bold: true}, totalValues));

        return rows;
    };

    const generate = async () => {
        const getImageBase64 = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };
        
        const logoImage = await getImageBase64(userLogo || '/logo.png');
        const headerImage = await getImageBase64('/header.png');
        const lastPage = await getImageBase64('/lastpage.png');
        
        let customPercent = 0;
        let docDefinition = {
            content: [
            ...(userLogo === null
                ? [{ image: headerImage, width: 595.28, height: 80, absolutePosition: { x: 0, y: 0 } }]
                : [{
                    canvas: [
                        {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 595.28,
                            h: 80,
                            color: userColor,
                        },
                    ],
                    absolutePosition: { x: 0, y: 0 },
                }]),
            {
                image: logoImage,
                fit: [230, 150],
                absolutePosition: { x: 20, y: 28 },
            }, {
                text: "Benefit Plan Proposal", 
                fontSize: 22,
                alignment: "center",
                margin: [0, 200, 0, 30]
            }, {
                text: `Prepared for ${proposal.clientName}`,
                margin: [0, 0, 0, 6],
                bold: true,
                alignment: "center",
                fontSize: 18,
            }, {
                text: `${formattedDate}`,
                fontSize: 15,
                alignment: "center"
            },
            {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 400, h: 2, color: '#223e7f',
                }],
                margin: [0, 20],
                alignment: "center"
            }, {
                text: `Your Agent Information:`,
                margin: [100, 10],
                fontSize: 18,
            }, {
                text: `${proposal.advisorName} \n ${proposal.advisorCompany} \n ${proposal.advisorEmail}`,
                fontSize: 15,
                margin: [100, 0],
            }, {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 400, h: 2, color: '#223e7f',
                }],
                alignment: "center",
                margin: [0, 20]
            }, {
                text: "Company Details:",
                fontSize: 18,
                margin: [100, 10]
            }, {
                table: {
                    widths: ['*', '*'],
                    body: headerBody
                },
                layout: {
                    paddingLeft: (i, node) => 0,
                    paddingRight: (i, node) => 0,
                    paddingTop: (i, node) => 5,
                    paddingBottom: (i, node) => 5,
                    hLineWidth: (i, node) => 0,
                    vLineWidth: (i, node) => 0,
                },
                margin: [100, 10],
            }, 
            ...Object.keys(bencatTables).map(accountType => {
                const totals = accountTotals[accountType] || { totalSingle: 0, totalCouple: 0, totalFamily: 0 };
                
                // if (proposal.companyType?.trim() === "Incorporated") {
                    return [
                        {
                            pageBreak: 'before',
                            table: {
                                widths: ['*'],
                                body: [
                                    [
                                        {
                                            text: `Your ${accountTypeHeaders[accountType]}`,
                                            color: 'white',
                                            fillColor: '#223e7f',
                                            bold: true,
                                            fontSize: 15,
                                            margin: [15, 0],
                                        }
                                    ]
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 0]
                        }, {
                            text: [
                                {text: `\nPlan Budget: $${planAccountBudget[accountType]} \n`},
                                {text: `Single Employees: ${totals.totalSingle} \n`},
                                {text: `Couples: ${totals.totalCouple} \n`},
                                {text: `Employees with Family: ${totals.totalFamily}`}
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            table: {
                                widths: ['*', '*', '*', '*'],
                                body: bencatTables[accountType],
                                style: 'lightHorizontalLines'
                            },
                            layout: {
                                paddingLeft: (i, node) => 5,
                                paddingRight: (i, node) => 5,
                                paddingTop: (i, node) => 5,
                                paddingBottom: (i, node) => 5,
                                hLineWidth: (i, node) => 0.5,
                                vLineWidth: (i, node) => 0.5,
                            },
                        },
                        { text: '\n' },
                        {
                            table: {
                                widths: ['*'],
                                body: [
                                    [
                                        {
                                            text: `Coverage Summary`,
                                            color: 'white',
                                            fillColor: '#223e7f',
                                            bold: true,
                                            fontSize: 15,
                                            margin: [15, 0],
                                        }
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        },
                        {
                            ul: accountTypeCoverageSummaries[accountType],
                            margin: [0, 5, 0, 10]
                        },
                        {
                            table: {
                                widths: ['*'],
                                body: [
                                    [
                                        {
                                            text: `Projected Cost for ${accountTypeHeaders[accountType]}`,
                                            color: 'white',
                                            fillColor: '#223e7f',
                                            bold: true,
                                            fontSize: 15,
                                            margin: [15, 0],
                                        }
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        },
                        {
                            text: `While the maximum cost for your plan is $${planAccountBudget[accountType]}/year, the actual cost varies depending on employee usage of the ${accountType}. Below is the table showing your costs based on different percentages of ${accountType} claimed.`,
                            margin: [15, 15, 0, 0]
                        },
                        {
                            text: `${accountType} Cost Estimate`,
                            fontSize: 15,
                            alignment: "center",
                            margin: [0, 15, 0, 15]
                        },
                        {
                            table: {
                                widths: ["25%", "*", "*", "*", "*", "*"],
                                body: generateCostEstimatesTable(accountType, accountEstimates, customPercent, proposal)
                            },
                            layout: {
                                paddingLeft: (i, node) => 5,
                                paddingRight: (i, node) => 5,
                                paddingTop: (i, node) => 5,
                                paddingBottom: (i, node) => 5,
                                hLineWidth: (i, node) => 0.5,
                                vLineWidth: (i, node) => 0.5,
                            },
                        },
                    ]
            }).flat(),
            ...(hasCoreHealth
                ? [
                    {
                        pageBreak: "before",
                        text: "Group Insurance Coverage",
                        alignment: "center",
                        fontSize: 15,
                        margin: [0, 10, 0, 10],
                    },
                    {
                        table: {
                            widths: ['*', '*', '*'],
                            body: groupInsuranceCoverageTable,
                        },
                        layout: {
                            hLineWidth: (i, node) => 0.5,
                            vLineWidth: (i, node) => 0.5,
                        }
                    },
                ]
                : []),
            {
                pageBreak: "before",
                text: "WHAT ARE YOU SIGNING UP FOR?",
                fontSize: 18,
                margin: [0, 70, 0, 0]
            }, {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 400, h: 1, color: '#223e7f'
                }],
                margin: [0, 10]
            }, {
                image: lastPage, width: 500, alignment: 'center', margin: [0, 30]
            }
            ],
            defaultStyle: {
                font: 'Roboto',
                fontSize: 10
            },
            tablePaddedLayout: {
                paddingLeft: (i, node) => 5,
                paddingRight: (i, node) => 5,
                paddingTop: (i, node) => 5,
                paddingBottom: (i, node) => 5,
                hLineWidth: (i, node) => 0.5,
                vLineWidth: (i, node) => 0.5,
            }
        };
        
        pdfMake.createPdf(docDefinition).open();
        // return new Promise((resolve, reject) => {
        //     pdfMake.createPdf(docDefinition).getBuffer(buffer => {
        //         const byteArray = new Uint8Array(buffer);
        //         const base64String = btoa(byteArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));
        //         resolve(base64String);
        //     });
        // });
    }

    return (
        <button className="btn btn-outline-primary btn-sm" style={{width: "100px"}} onClick={generate}>Download</button>
    );
}

export default PDFGenerator;